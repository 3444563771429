(function (angular, undefined) {
    "use strict";
    var projects = angular.module("mikesProjects");

    projects.factory("ProjectService", ["$http", "$q", "$localStorage",
        function ($http, $q, $localStorage) {
        var getQueryString = function () {
            var date;
            if ($localStorage.projects instanceof Array && $localStorage.projects.length > 0) {
                date = _.last($localStorage.projects.sort(function (a, b) {
                    return (new Date(a.created)).getTime() - (new Date(b.created)).getTime();
                }));
                if (_.has(date, "date")) {
                    return "?after=" + (new Date(date.created)).getTime();
                }
            }
            return "";
        },
        randomString = function () {
            return Math.random().toString().substring(2);
        };

        return {
            "getProjects": function () {
                var def = $q.defer(),
                    query = getQueryString();

                $http.get("/api/project/list" + query)
                    .then(function (res) {
                        if (!query) {
                            $localStorage.projects = [];
                        }

                        $localStorage.projects = _.uniqBy(
                            _.union(
                                _.map(res.data, function (p) {
                                    _.set(p, "target", randomString());
                                    return p;
                                }),
                                $localStorage.projects
                            ),
                            function (item) {
                                return item._id;
                            }
                        ).filter(function (item) { return !item.hidden; });

                        $localStorage.projects = $localStorage.projects.sort(function (a, b) {
                            return b.id - a.id;
                        });

                        def.resolve();
                    })
                    .catch(function (err) {
                        def.reject();
                    });

                return def.promise;
            }
        };
    }]);

})(angular);
